import { render, staticRenderFns } from "./Lgx189086389025898496.vue?vue&type=template&id=582f7cbb&scoped=true&"
import script from "./Lgx189086389025898496.vue?vue&type=script&lang=js&"
export * from "./Lgx189086389025898496.vue?vue&type=script&lang=js&"
import style0 from "./Lgx189086389025898496.vue?vue&type=style&index=0&id=582f7cbb&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "582f7cbb",
  null
  
)

export default component.exports