/* eslint-disable */

<template>
  <div>
    <vue-sortable :options="options" @drag:start="startedDrag" @drag:stop="stoppedDrag" @sortable:sort="sort">
      <div class="vx-row" :class="{ 'cursor-drag': draggingCard === true }">
        <div class="vx-col lg:w-3/12 md:w-3/12 sm:w-5/5">
          <vx-card class="mb-base">
            <h3 class="font-bold mb-4">Commands</h3>
            <vue-draggable-container id="wordBank" class="wordBank">
              <h5 class="dragBlock my-4 font-semibold cursor-pointer SSHPasswordCommand" v-for="i in commands" :key="i">{{ i }}</h5>
            </vue-draggable-container>
          </vx-card>
        </div>
        <div class="vx-col lg:w-9/12 md:w-9/12 sm:w-5/5">
          <vx-card class="mb-base">
            <div class="fourth-sim pt-0">
              <div class="vx-row mb-4">
                <div class="vx-col w-6/12 flex items-center">
                  <h2 class="font-bold">Command Order</h2>
                </div>
                <div class="vx-col w-6/12 flex items-center">
                  <h2 class="font-bold">SSH Client</h2>
                </div>
              </div>
              <div class="vx-row border-white border-2 border-solid rounded p-4">
                <div class="vx-col w-6/12 flex items-center">
                  <img
                    draggable="false"
                    src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/189086389025898496/number-one.png"
                    width="50"
                    class="mr-4"
                  />
                  <h4 class="font-semibold">Command #1</h4>
                </div>
                <div class="vx-col w-6/12">
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg" id="input0">
                  </vue-draggable-container>
                </div>
              </div>
              <div class="vx-row border-white border-2 border-solid rounded p-4 my-4">
                <div class="vx-col w-6/12 flex items-center">
                  <img
                    draggable="false"
                    src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/189086389025898496/number-two.png"
                    width="50"
                    class="mr-4"
                  />
                  <h4 class="font-semibold">Command #2</h4>
                </div>
                <div class="vx-col w-6/12">
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg" id="input1">
                  </vue-draggable-container>
                </div>
              </div>
              <div class="vx-row border-white border-2 border-solid rounded p-4 my-4">
                <div class="vx-col w-6/12 flex items-center">
                  <img
                    draggable="false"
                    src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/189086389025898496/number-three.png"
                    width="50"
                    class="mr-4"
                  />
                  <h4 class="font-semibold">Command #3</h4>
                </div>
                <div class="vx-col w-6/12">
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg" id="input2">
                  </vue-draggable-container>
                </div>
              </div>
            </div>
          </vx-card>
        </div>
      </div>
    </vue-sortable>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import Prism from 'vue-prism-component';
import shuffle from 'shuffle-array';

export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),
      draggingCard: false,

      options: {
        draggable: '.dragBlock',
        dropzone: '.dropzoneStandard',
      },

      commands: ['ssh root@server', 'ssh-copy-id –i ~/.ssh/id_rsa.pub user@server', 'ssh -keygen -t rsa'],
    };
  },
  methods: {
    startedDrag(x) {
      x.data.source.className += ' currently-dragging-standard';
      this.draggingCard = true;
    },
    stoppedDrag() {
      this.draggingCard = false;
    },
    sort(x) {
      const overContainer = x.data.dragEvent.data.overContainer;
      const sourceContainer = x.data.dragEvent.data.sourceContainer;
      if (overContainer && overContainer.id !== 'wordBank' && overContainer.children) {
        if (overContainer.children.length > 0 && overContainer.id !== sourceContainer.id) {
          //this.drag_error()
          x.cancel();
        }
      }
    },
    markSimulation() {
      let totalScore = 0;

      if (document.getElementById('input0').textContent === 'ssh -keygen -t rsa') {
        document.getElementById('input0').style.borderColor = '#28C76F';
        document.getElementById('input0').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input0').style.borderColor = '#fd4445';
        document.getElementById('input0').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input1').textContent === 'ssh-copy-id –i ~/.ssh/id_rsa.pub user@server') {
        document.getElementById('input1').style.borderColor = '#28C76F';
        document.getElementById('input1').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input1').style.borderColor = '#fd4445';
        document.getElementById('input1').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input2').textContent === 'ssh root@server') {
        document.getElementById('input2').style.borderColor = '#28C76F';
        document.getElementById('input2').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input2').style.borderColor = '#fd4445';
        document.getElementById('input2').style.backgroundColor = '#fd4445';
      }

      if (totalScore === this.dataLocal.simulation.totalScore) {
        return this.$emit('simulation_full_correct', totalScore);
      }

      this.$emit('simulation_getting_there', totalScore);
    },
  },
  created() {
    this.commands = shuffle(this.commands);
  },
  components: {
    draggable,
    Prism,
    shuffle,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style scoped>
.SSHPasswordCommand {
  word-break: break-all !important;
  text-align: left !important;
}
</style>
